<template>
  <section id="services" class="services">
    <div class="container">
      <div class="services__title" v-if="content.services_title" v-html="content.services_title"></div>
      <div class="services__description" v-if="content.services_desc" v-html="content.services_desc"></div>
      <ul class="services__list" v-if="content.services_list">
        <ItemService v-for="item in content.services_list.slice(0, countItem)" :key="item" :content="item" />
      </ul>
      <Link parent-class="services__button" v-if="content.services_list.length > countItem" v-on:click="outputItem"
        >Смотреть еще</Link
      >
    </div>
  </section>
</template>

<script>
import ItemService from "@/components/block/ItemServiceApp.vue";
import Link from "@/components/element/LinkApp.vue";

export default {
  components: {
    Link,
    ItemService,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.services,
      countItem: 100,
    };
  },
  methods: {
    outputItem() {
      this.countItem *= 2;
    },
  },
};
</script>

<style>
.services {
  margin-bottom: 170px;
  height: auto;
  overflow: hidden;
  transition: height 1s ease;
}

.services__title {
  margin-bottom: 10px;

  font-family: var(--title2-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--title2-fonts-size);
  line-height: var(--title2-line-height);
  text-align: center;
  color: var(--title2-color-white);
}

.services__description {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 70px;

  font-family: var(--subtitle3-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--subtitle3-fonts-size);
  line-height: var(--subtitle3-line-height);
  text-align: center;
  color: var(--subtitle3-color-white);
}

.services__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 60px 30px;
  grid-template-areas: ". . . .";
  margin-bottom: 70px;
}

.services__button {
  margin: 0 auto;
}

@media (max-width: 1599.98px) {
  .services {
    margin-bottom: 150px;
  }

  .services__title {
    font-size: 40px;
    line-height: 120%;
  }

  .services__description {
    max-width: 450px;
    margin-bottom: 60px;

    font-size: 14px;
  }

  .services__list {
    margin-bottom: 60px;
  }
}

@media (max-width: 1199.98px) {
  .services {
    margin-bottom: 130px;
  }

  .services__title {
    font-size: 32px;
  }

  .services__description {
    max-width: 364px;
    margin-bottom: 55px;
  }

  .services__list {
    margin-bottom: 55px;
  }
}

@media (max-width: 991.98px) {
  .services {
    margin-bottom: 110px;
  }

  .services__title {
    font-size: 28px;
  }

  .services__description {
    margin-bottom: 50px;

    font-size: 14px;
  }

  .services__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 40px 20px;
    grid-template-areas: ". .";
    margin-bottom: 50px;
  }
}

@media (max-width: 767.98px) {
  .services {
    margin-bottom: 90px;
  }

  .services__title {
    font-size: 26px;
  }

  .services__description {
    max-width: calc(100% - 30px);
    width: 364px;
    margin-bottom: 45px;
  }

  .services__list {
    gap: 28px 14px;
  }
}
</style>
