<template>
  <section id="company" class="company">
    <div class="company__swiper swiper" v-if="content.company_slides" data-aos="custom-img" data-aos-duration="1200">
      <ul class="company__wrapper swiper-wrapper">
        <li class="company__slide swiper-slide" v-for="item in content.company_slides" :key="item">
          <img class="company__img-slide" :src="$mediaWp(item, 'company_slide')" alt="" />
        </li>
      </ul>
      <div class="company__controls">
        <ButtonSlidePrev parent-class="company__prev" />
        <ButtonSlideNext parent-class="company__next" />
      </div>
      <PaginationSlide parent-class="company__pagination" />
    </div>
    <div class="company__content container">
      <div class="company__left">
        <img
          class="company__img"
          v-lazy:src="$mediaWp(content.company_img, 'company_img')"
          alt=""
          v-if="content.company_img"
          data-aos="custom-img"
          data-aos-duration="1200" />
      </div>
      <div class="company__right">
        <div
          class="company__title"
          v-if="content.company_title"
          data-aos="custom-appearance"
          data-aos-duration="1000"
          data-aos-offset="-285"
          >{{ content.company_title }}</div
        >
        <UserContent
          v-html="content.company_desc"
          v-if="content.company_desc"
          data-aos="custom-appearance"
          data-aos-duration="1000"
          data-aos-offset="-150"></UserContent>
      </div>
    </div>
  </section>
</template>

<script>
import UserContent from "@/components/element/UserContentApp.vue";
import ButtonSlideNext from "@/components/element/ButtonSlideNextApp.vue";
import ButtonSlidePrev from "@/components/element/ButtonSlidePrevApp.vue";
import PaginationSlide from "@/components/element/PaginationSlide.vue";
import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

export default {
  components: {
    UserContent,
    ButtonSlidePrev,
    ButtonSlideNext,
    PaginationSlide,
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse.company,
    };
  },
  mounted() {
    new Swiper(".company__swiper", {
      modules: [Navigation, Pagination, EffectFade, Autoplay],
      slidesPerView: 1,
      loop: true,
      speed: 500,
      grabCursor: true,
      effect: "fade",
      autoplay: {
        enabled: true,
        delay: 5000,
        reverseDirection: true,
      },
      navigation: {
        nextEl: ".company__next",
        prevEl: ".company__prev",
      },
      pagination: {
        el: ".company__pagination",
        type: "bullets",
      },
    });
  },
};
</script>

<style>
.company {
  margin-bottom: 170px;

  overflow: hidden;
}

.company__slide {
  height: 902px;
}

.company__img-slide {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.company__controls {
  position: absolute;
  left: calc(50% - 705px);
  bottom: 50px;
  z-index: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px;
}

.company__pagination {
  display: none;
}

.company__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.company__left {
  width: 450px;
  height: 472px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 100px;
}

.company__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.company__right {
  position: relative;
  width: 840px;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 170px 60px 60px 150px;
  margin-bottom: -282px;

  background-color: #ffffff;
  transform: translateY(-282px);
  z-index: 3;
}

.company__right::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  width: 50vw;
  height: 100%;

  background-color: #ffffff;
  transform: translateX(99%);
}

.company__title {
  margin-bottom: 70px;

  font-family: var(--title2-fonts-family);
  font-style: normal;
  font-weight: 400;
  font-size: var(--title2-fonts-size);
  line-height: var(--title2-line-height);
  color: var(--title2-color-white);
}

@media (max-width: 1599.98px) {
  .company {
    margin-bottom: 150px;
  }

  .company__slide {
    height: 568px;
  }

  .company__controls {
    left: calc(50% - 537px);
    bottom: 40px;

    width: 110px;
  }

  .company__left {
    width: 339px;
    height: 370px;
    padding-top: 90px;
  }

  .company__right {
    width: 644px;

    padding: 150px 0 0 122px;
    margin-bottom: -167px;

    transform: translateY(-167px);
  }

  .company__title {
    margin-bottom: 60px;

    font-size: 40px;
  }
}

@media (max-width: 1199.98px) {
  .company {
    margin-bottom: 130px;
  }

  .company__slide {
    height: 484px;
  }

  .company__controls {
    left: calc(50% - 483px);
    bottom: 30px;

    width: 90px;
  }

  .company__left {
    width: 302px;
    height: 330px;
    padding-top: 80px;
  }

  .company__right {
    width: 634px;

    padding: 130px 0 0 83px;
    margin-bottom: -142px;

    transform: translateY(-142px);
  }

  .company__title {
    margin-bottom: 55px;

    font-size: 32px;
  }
}

@media (max-width: 991.98px) {
  .company {
    margin-bottom: 110px;
  }

  .company__swiper {
    margin-bottom: 50px;
  }

  .company__slide {
    height: 364px;
  }

  .company__controls {
    display: none;
  }

  .company__pagination {
    display: block;
  }

  .company__pagination {
    display: flex;
    width: 290px !important;
    margin: 35px auto 0 auto;
  }

  .company__content {
    flex-direction: column-reverse;
  }

  .company__left {
    width: 286px;
    height: 236px;
    padding-top: 0;
  }

  .company__right {
    width: 100%;
    padding: 0;
    margin-bottom: 60px;

    transform: translateY(0);
    z-index: 3;
  }

  .company__right::before {
    display: none;
  }

  .company__title {
    margin-bottom: 50px;
  }
}

@media (max-width: 767.98px) {
  .company {
    margin-bottom: 90px;
  }

  .company__swiper {
    margin-bottom: 45px;
  }

  .company__slide {
    height: auto;
    max-height: 350px;
  }

  .company__left {
    width: 220px;
    height: 182px;
  }

  .company__right {
    padding: 0;
    margin-bottom: 45px;

    transform: translateY(0);
    z-index: 3;
  }

  .company__right::before {
    display: none;
  }

  .company__title {
    margin-bottom: 45px;

    font-size: 26px;
  }
}
</style>
