<template>
  <div id="map" class="map"></div>
</template>

<script>
import ymaps from "ymaps";

export default {
  props: ["content"],
  mounted() {
    let context = this;
    setTimeout(function () {
      ymaps
        .load(`https://api-maps.yandex.ru/2.1/?apikey=${window.settingsApp.apiMap}&lang=ru_RU`)
        .then((maps) => {
          const map = new maps.Map("map", {
            center: context.content.contacts_map_center.split(","),
            controls: [],
            zoom: 12,
          });

          // Метка
          let myPlacemark = new maps.Placemark(
            context.content.contacts_map_mark.split(","),
            {},
            {
              iconLayout: "default#image",
              iconImageHref: context.$mediaWp(context.content.contacts_map_img),
              iconImageSize: [50, 66],
              iconImageOffset: [-25, -66],
            }
          );

          // Добавление метки на карту
          map.geoObjects.add(myPlacemark);
        })
        .catch((error) => console.log("Failed to load Yandex Maps", error));
    }, 5000);
  },
};
</script>

<style>
.map {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

[class*="ymaps-2"][class*="-copyrights-promo"] {
  display: none;
}

@media (max-width: 532.98px) {
  .map {
    display: none;
  }
}
</style>
